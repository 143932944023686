<template>
  <div>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'splash-screen-settings',
      resourceName: 'splash-screen-settings',
      singularName: 'splash-screen-setting',
      categories: [],
      searchFields: {
        title: null,
        status: null,
        category_id: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components


}
</script>
